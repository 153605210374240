.faq-container {
  font-family: 'Lato, sans-serif';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
}

.faq-wrapper {
  width: 60%;
}

.faq-heading {
  margin-bottom: 20px;
}

.faq-item {
  background-color: white;
  color: black;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  margin: 20px 0;
  overflow: hidden;
}

.question {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px 80px 20px 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.question:hover {
  background-color: #f9f9f9;
}

.icon {
  font-size: 2.2rem;
  position: absolute;
  right: 20px;
  transition: transform 0.2s ease;
}

.active .icon {
  transform: rotate(0deg);
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.shown {
  max-height: 200px;
}

.snake {
  position: absolute;
  width: 50px;
  /* Adjust the size as needed */
  height: auto;
  pointer-events: none;
  /* Ensures the snake doesn't interfere with mouse events */
}